import { ANNUALLEAVE } from "constants/serviceNames";
import { ANNUALLEAVE_APPROVALS } from "constants/taskNames";
import { formatDate } from "@sse-digital-services/design-system";

const annualLeaveMapper = {
    mapALRequestApprovals: (leaveRequests) => {
        if (leaveRequests) {
            return leaveRequests.map((req) => ({
                id: req.id,
                requester: req.requester_name,
                leaveType: req.leaveType,
                startDate: formatDate(req.startDate),
                endDate: formatDate(req.endDate),
                createdDate: formatDate(req.createdDate),
                updatedDate: req.updatedDate
                    ? formatDate(req.updatedDate)
                    : req.updatedDate,
                approvalStatus: req.approvalStatus,
                duration: req.duration,
                comment: req.comment,
                availabilityForStandBy: req.availabilityForStandBy,
                standbyToBeCoveredBy: req.standbyToBeCoveredBy,
                // url: apiApproval.url,
                taskType: ANNUALLEAVE_APPROVALS,
                service: ANNUALLEAVE,
            }));
        }

        return [];
    },
};

export default annualLeaveMapper;
