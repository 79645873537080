import PropTypes from "prop-types";
import * as React from "react";
import QuickActionTile from "../templates/quickActionTile";
import SingleLinkTile from "../templates/singleLinkTile";
import { annualLeaveUrl } from "../../../services/apiUtils/config/urls";
import { quickActionTilesMap } from "../tilesMap";

const AnnualLeaveTile = ({ id }) => {
    return (
        <QuickActionTile id={id}>
            <SingleLinkTile
                link={annualLeaveUrl}
                title={"Annual Leave"}
                linkText={quickActionTilesMap[id].description}
            />
        </QuickActionTile>
    );
};

AnnualLeaveTile.propTypes = {
    id: PropTypes.string.isRequired,
};

export default AnnualLeaveTile;
