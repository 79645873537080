import {
    avatarURL,
    documentProcessorURL,
    edisonReportingURL,
    iBuyAPICartsURL,
    iBuyAPIRequisitionURL,
    iBuyAPISearchURL,
    integrationAPIURL,
    notificationsURL,
    preferencesURL,
    searchUrl,
    userProfileURL,
    annualLeaveAPIURL,
} from "./urls";
import {
    iBuyCartTokenRequest,
    iBuyRequisitionsTokenRequest,
    iBuySearchTokenRequest,
    loginRequest,
    preferencesRequest,
    tokenRequest,
    userProfileRequest,
} from "../../auth/authConfig";

export const apiConfig = {
    preferences: {
        url: preferencesURL,
        tokenRequest: preferencesRequest,
    },
    notifications: {
        url: notificationsURL,
        tokenRequest: tokenRequest,
    },
    carts: {
        url: iBuyAPICartsURL,
        tokenRequest: iBuyCartTokenRequest,
    },
    requisitions: {
        url: iBuyAPIRequisitionURL,
        tokenRequest: iBuyRequisitionsTokenRequest,
    },
    search: {
        url: iBuyAPISearchURL,
        tokenRequest: iBuySearchTokenRequest,
    },
    avatar: {
        url: avatarURL,
        tokenRequest: loginRequest,
    },
    integrations: {
        url: integrationAPIURL,
        tokenRequest: tokenRequest,
    },
    userProfile: {
        url: userProfileURL,
        tokenRequest: userProfileRequest,
    },
    searchAssistant: {
        url: searchUrl,
        tokenRequest: tokenRequest,
    },
    documentProcessor: {
        url: documentProcessorURL,
        tokenRequest: tokenRequest,
    },
    edisonReporting: {
        url: edisonReportingURL,
        tokenRequest: tokenRequest,
    },
    annualLeave: {
        url: annualLeaveAPIURL,
        tokenRequest: tokenRequest,
    },
};
