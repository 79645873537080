import TaskActionButton from "../../buttons/taskActionButton";
import CardActions from "@mui/material/CardActions";
import * as React from "react";
import { useState } from "react";
import * as PropTypes from "prop-types";
import BaseTaskCard from "../baseTaskCard";
import AnnualLeaveTaskDialog from "./annualLeaveTaskDialog";
import useAnnualLeaveRequests from "services/apiUtils/hooks/useAnnualLeaveRequests";
import { annualLeaveUrl } from "../../../services/apiUtils/config/urls";

const AnnualLeaveTaskCard = ({ id, isDashboard }) => {
    const { leaveRequestById } = useAnnualLeaveRequests();
    const {
        requester,
        leaveType,
        createdDate,
        startDate,
        endDate,
        updatedDate,
        approvalStatus,
        comment,
        duration,
        availabilityForStandBy,
        standbyToBeCoveredBy,
    } = leaveRequestById(id);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const openDialog = () => {
        setIsDialogOpen(true);
    };
    const rows = [
        { field: "Requested by", value: requester },
        { field: "Leave type", value: leaveType },
    ];
    !isDashboard &&
        rows.push(
            { field: "Start date", value: startDate },
            { field: "End date", value: endDate }
        );

    return (
        <BaseTaskCard
            fields={rows}
            title={`Leave Request`}
            action={openDialog}
            isDashboard={isDashboard}
        >
            <CardActions
                sx={{
                    p: "0px",
                    display: "flex",
                    flexDirection: "row",
                }}
            >
                <TaskActionButton
                    mode="neutral"
                    action="View in Annual Leave"
                    url={annualLeaveUrl}
                />
            </CardActions>
            <AnnualLeaveTaskDialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                id={id}
                requester={requester}
                leaveType={leaveType}
                startDate={startDate}
                endDate={endDate}
                createdDate={createdDate}
                updatedDate={updatedDate}
                approvalStatus={approvalStatus}
                comment={comment}
                duration={duration}
                availabilityForStandBy={availabilityForStandBy}
                standbyToBeCoveredBy={standbyToBeCoveredBy}
                url={annualLeaveUrl}
            />
        </BaseTaskCard>
    );
};

AnnualLeaveTaskCard.propTypes = {
    id: PropTypes.string.isRequired,
    isDashboard: PropTypes.bool,
};

export default AnnualLeaveTaskCard;
