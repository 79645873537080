import apiService from "../apiService";
import { apiConfig } from "../config/apiConfig";

const AnnualLeaveRequestsClient = {
    getAnnualLeaveUser: () => {
        const apiHeaderConfig = {
            headers: {
                "api-version": "v1",
            },
        };

        const annualLeaveAPIService = apiService(
            apiConfig.annualLeave,
            apiHeaderConfig
        );
        return annualLeaveAPIService.get("get_al_user");
    },

    getALRequestsForMyApproval: (annualLeaveUser) => {
        const apiHeaderConfig = {
            headers: {
                "api-version": "v1",
                al_user_id: annualLeaveUser.al_user_id,
            },
        };

        const annualLeaveAPIService = apiService(
            apiConfig.annualLeave,
            apiHeaderConfig
        );

        return annualLeaveAPIService.get(`get_al_requests_for_my_approvals`);
    },
};

export default AnnualLeaveRequestsClient;
