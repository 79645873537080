import React from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import DialogContent from "@mui/material/DialogContent";
import { useHighContrastBorderSX } from "@sse-digital-services/design-system";
import PropTypes from "prop-types";
import TaskActionButton from "../../buttons/taskActionButton";
import TaskDialogTitle from "../taskDialogTitle";

const IBuyTaskDialog = ({
    id,
    description,
    supplier,
    date,
    requester,
    amount,
    open,
    onClose,
    url,
}) => {
    const { borderSX } = useHighContrastBorderSX();
    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: borderSX,
            }}
        >
            <TaskDialogTitle onClose={onClose}></TaskDialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <Typography sx={{ fontWeight: "bold" }}>
                            Requisition
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography sx={{ fontWeight: "bold" }}>
                            {id}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>Description</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>{description}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>Supplier</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>{supplier}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>Date Created</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>{date}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>Requester</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>{requester}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>Total Price</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>{`${amount}`}</Typography>
                    </Grid>
                    <Grid item xs={12} my={1}>
                        <TaskActionButton
                            buttonSx={{ pl: "0px" }}
                            mode="neutral"
                            action="View in iBuy"
                            url={url}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

IBuyTaskDialog.propTypes = {
    id: PropTypes.string.isRequired,
    description: PropTypes.string,
    supplier: PropTypes.string,
    date: PropTypes.string,
    requester: PropTypes.string,
    amount: PropTypes.string,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    url: PropTypes.string,
};

export default IBuyTaskDialog;
