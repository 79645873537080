import React from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import DialogContent from "@mui/material/DialogContent";
import { useHighContrastBorderSX } from "@sse-digital-services/design-system";
import PropTypes from "prop-types";
import TaskActionButton from "../../buttons/taskActionButton";
import TaskDialogTitle from "../taskDialogTitle";

const AnnualLeaveTaskDialog = ({
    id,
    requester,
    leaveType,
    startDate,
    endDate,
    createdDate,
    updatedDate,
    approvalStatus,
    duration,
    comment,
    availabilityForStandBy,
    standbyToBeCoveredBy,
    onClose,
    url,
    open,
}) => {
    const { borderSX } = useHighContrastBorderSX();
    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: borderSX,
            }}
        >
            <TaskDialogTitle onClose={onClose}></TaskDialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <Typography sx={{ fontWeight: "bold" }}>
                            Requested By
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography sx={{ fontWeight: "bold" }}>
                            {requester}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>Leave Id</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>{id}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>Leave Type</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>{leaveType}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>Approval Status</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>{approvalStatus}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>Start Date</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>{startDate}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>End Date</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>{endDate}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>Duration</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>{duration} Hrs</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>Comments</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>{comment}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>Date Created</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>{createdDate}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>Update Date</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>{updatedDate}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>Availability for Standby</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>
                            {availabilityForStandBy ? "Yes" : "No"}
                        </Typography>
                    </Grid>
                    {availabilityForStandBy && (
                        <>
                            <Grid item xs={4}>
                                <Typography>
                                    Standby to be covered by
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography>{standbyToBeCoveredBy}</Typography>
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12} my={1}>
                        <TaskActionButton
                            buttonSx={{ pl: "0px" }}
                            mode="neutral"
                            action="View in Annual Leave"
                            url={url}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

AnnualLeaveTaskDialog.propTypes = {
    id: PropTypes.string.isRequired,
    requester: PropTypes.string,
    leaveType: PropTypes.string.isRequired,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    updatedDate: PropTypes.string,
    createdDate: PropTypes.string,
    comment: PropTypes.string,
    duration: PropTypes.number,
    approvalStatus: PropTypes.string,
    availabilityForStandBy: PropTypes.bool,
    standbyToBeCoveredBy: PropTypes.string,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    url: PropTypes.string,
};

export default AnnualLeaveTaskDialog;
