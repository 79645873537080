const { DialogTitle, IconButton } = require("@mui/material");
import { Close } from "@sse-digital-services/design-system";
import PropTypes from "prop-types";

const TaskDialogTitle = ({ onClose }) => {
    return (
        <DialogTitle>
            <IconButton
                data-track-id="close dialog"
                aria-label="close"
                onClick={onClose}
                size="small"
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                }}
            >
                <Close
                    sx={{
                        color: "common.black",
                        backgroundColor: "common.white",
                        borderRadius: "50%",
                    }}
                />
            </IconButton>
        </DialogTitle>
    );
};

TaskDialogTitle.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default TaskDialogTitle;
