import useErrorHandling, { ErrorTypes } from "../../../errors/useErrorHandling";
import useSWR from "swr";
import AnnualLeaveRequestsClient from "../clients/annualLeaveRequestsClient";
import usePreferences from "./usePreferences";
import { ANNUALLEAVE } from "../../../constants/serviceNames";
import { useMemo } from "react";

const useAnnualLeaveUser = () => {
    const {
        servicesInMaintenanceMode,
        servicesBasedOnPermissions,
        preferencesAreLoading,
    } = usePreferences();
    const { throwAsyncError } = useErrorHandling();

    const {
        data: annualLeaveUser,
        mutate: getAnnualLeaveUserId,
        isLoading: annualLeaveUserDataIsLoading,
        error: annualLeaveError,
    } = useSWR(
        () => (preferencesAreLoading ? null : "AnnualLeaveUser"),
        async () => {
            try {
                if (
                    servicesInMaintenanceMode &&
                    servicesBasedOnPermissions.includes(ANNUALLEAVE)
                ) {
                    if (
                        !servicesInMaintenanceMode.find(
                            (service) => service === ANNUALLEAVE
                        )
                    ) {
                        const annualLeaveUserData =
                            await AnnualLeaveRequestsClient.getAnnualLeaveUser();
                        return annualLeaveUserData;
                    }
                }
            } catch (error) {
                // Display message from API in case of 404 (user not found in AnnualLeave)
                let message =
                    error?.response?.data?.errorCode === 404
                        ? "You are not found in the annual leave system."
                        : error?.response?.data?.detail;
                if (error?.response?.data?.errorCode === 404)
                    throwAsyncError(
                        ErrorTypes.AnnualLeaveAPIError,
                        message,
                        error
                    );
                throw new Error(
                    "You are not found in the annual leave system."
                );
            }
        },
        { fallbackData: "" }
    );

    const annualLeaveUserIsLoading = useMemo(
        () => annualLeaveUserDataIsLoading || preferencesAreLoading,
        [annualLeaveUserDataIsLoading, preferencesAreLoading]
    );

    return {
        annualLeaveUser,
        getAnnualLeaveUserId,
        annualLeaveUserIsLoading,
        errorMessage: annualLeaveError?.message,
    };
};

export default useAnnualLeaveUser;
