import usePreferences from "./usePreferences";
import useErrorHandling, { ErrorTypes } from "../../../errors/useErrorHandling";
import annualLeaveRequestsClient from "../clients/annualLeaveRequestsClient";
import { ANNUALLEAVE } from "../../../constants/serviceNames";
import { useDispatch } from "react-redux";
import { setAnnualLeaveRequestTaskCount } from "../../../redux/actions/taskCountActions";
import { useCallback, useMemo } from "react";
import useAnnualLeaveUser from "./useAnnualLeaveUser";
import useSWR from "swr";
import annualLeaveMapper from "../mappers/annualLeaveMapper";

const useAnnualLeaveRequests = () => {
    const dispatch = useDispatch();
    const {
        servicesInMaintenanceMode,
        servicesBasedOnPermissions,
        preferencesAreLoading,
    } = usePreferences();

    const { annualLeaveUser, annualLeaveUserIsLoading } = useAnnualLeaveUser();
    const { throwAsyncError } = useErrorHandling();

    const {
        data: annualLeaveRequests,
        isLoading: annualLeaveRequestsDataLoading,
    } = useSWR(
        () =>
            preferencesAreLoading || annualLeaveUserIsLoading
                ? null
                : "AnnualLeaveRequests",
        async () => {
            try {
                if (
                    servicesBasedOnPermissions.includes(ANNUALLEAVE) &&
                    servicesInMaintenanceMode &&
                    annualLeaveUser
                ) {
                    if (
                        !servicesInMaintenanceMode.find(
                            (service) => service === ANNUALLEAVE
                        )
                    ) {
                        const leaveRequestsData =
                            await annualLeaveRequestsClient.getALRequestsForMyApproval(
                                annualLeaveUser
                            );
                        const approvalsMapped =
                            annualLeaveMapper.mapALRequestApprovals(
                                leaveRequestsData.data
                            );
                        dispatch(
                            setAnnualLeaveRequestTaskCount(
                                leaveRequestsData.count
                            )
                        );
                        return approvalsMapped;
                    }
                }
            } catch (error) {
                // Display message from API in case of 404 (user not found in ITSC)
                let message =
                    error?.response?.data?.errorCode === 404
                        ? error?.response?.data?.detail
                        : "Unable to fetch annual leave requests";

                throwAsyncError(ErrorTypes.AnnualLeaveAPIError, message, error);
            }
        },
        { fallbackData: [], parallel: true, revalidateFirstPage: false }
    );

    const leaveRequestById = useCallback(
        (id) => {
            if (annualLeaveRequests) {
                const request = annualLeaveRequests.find((request) => {
                    return request.id === id;
                });
                return request ?? {};
            }
            return {};
        },
        [annualLeaveRequests]
    );

    const annualLeaveRequestsAreLoading = useMemo(
        () =>
            annualLeaveRequestsDataLoading ||
            annualLeaveUserIsLoading ||
            preferencesAreLoading,
        [
            annualLeaveRequestsDataLoading,
            annualLeaveUserIsLoading,
            preferencesAreLoading,
        ]
    );

    return {
        annualLeaveRequests,
        annualLeaveRequestsAreLoading,
        leaveRequestById,
    };
};

export default useAnnualLeaveRequests;
